const initialState = {
  openAdSlug: null,
};

const SET_OPEN_AD_SLUG = "adNetworks/setOpenAdSlug";
const CLEAR_OPEN_AD_SLUG = "adNetworks/clearOpenAdSlug";

export const setOpenAdSlug = (slug) => ({
  type: SET_OPEN_AD_SLUG,
  payload: slug,
});

export const clearOpenAdSlug = () => ({
  type: CLEAR_OPEN_AD_SLUG,
});


export const adNetworksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_AD_SLUG:
      return {
        ...state,
        openAdSlug: action.payload,
      };
    case CLEAR_OPEN_AD_SLUG:
      return {
        ...state,
        openAdSlug: null,
      };
    default:
      return state;
  }
};
