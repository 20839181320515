import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { adNetworksReducer } from "./adNetworksSlice";
import booleanReducer from "./booleanSlice";
import { modalReducer } from "./modalSlice";

const rootReducer = combineReducers({
  adNetworks: adNetworksReducer,
  boolean: booleanReducer,
  modal: modalReducer,
});

const preloadedState = {
  adNetworks: {
    ...adNetworksReducer(undefined, { type: "@@INIT" }),
    selectedNetworks: [],
    openAdSlug: null,
  },
  modal: {
    isModalOpen: false,
  },
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

export default store;
