const initialState = {
  isModalOpen: false,
};

const SET_MODAL_OPEN = "modal/setModalOpen";
const SET_MODAL_CLOSE = "modal/setModalClose";

export const setModalOpen = () => ({
  type: SET_MODAL_OPEN,
});

export const setModalClose = () => ({
  type: SET_MODAL_CLOSE,
});

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true,
      };
    case SET_MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false,
      };
    default:
      return state;
  }
};
